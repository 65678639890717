body,
html {
  padding: 0;
  margin: 0;
}

fieldset {
  border: none !important;
}

main {
  width: 100%;
}

.hidden {
  display: block;
}

.show {
  display: none;
}

@media (min-width: 599px) {
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
}

a {
  font-weight: normal;
  z-index: 999;
  word-break: break-all;
}

.badge {
  background-color: #A83811;
  color: #fff;
  padding: 0.5px 3px;
  border-radius: 5px;
  margin-left: 4px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}